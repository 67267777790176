import { ArrowRightIcon } from "@heroicons/react/outline";
import Button from "common/components/Button";
import { selectOrder, updateDeliveryDestination } from "common/redux/order";
import { calculateDelivery } from "common/redux/order";
import { useAppDispatch, useAppSelector } from "common/redux/store";
import { createCheckout } from "common/services/Payment";
import getStripe from "common/utils/stripe";
import React, { useCallback, useMemo, useState } from "react";
import { useFormContext } from "react-hook-form";

import Costs from "../Costs";
import AgreementSwitch from "./AgreementSwitch";
import ErrorMessage from "./ErrorMessage";
import PickDeliveryType from "./PickDeliveryType";
import TransportForm from "./TransportForm";

const Form: React.FC = () => {
  const [error, setError] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [agreeToTerms, setAgreeToTerms] = useState<boolean>(false);

  const { handleSubmit, getValues, watch } = useFormContext();

  const dispatch = useAppDispatch();

  const order = useAppSelector(selectOrder());

  const { product, delivery } = order;

  const onSubmit = () => {
    const formData = getValues();

    dispatch(updateDeliveryDestination(formData));
    dispatch(
      calculateDelivery({ originAddress: product.location, ...formData })
    );
  };

  const formState = watch();

  const submitable = useMemo(() => {
    const { coordinates, ...reduxState } = order.delivery.destination;

    const matchingFormAndReduxState =
      JSON.stringify(formState) === JSON.stringify(reduxState);

    return matchingFormAndReduxState && order.submitable;
  }, [formState, order.delivery.destination, order.submitable]);

  const handleCheckout = useCallback(async () => {
    if (!submitable || !product) return;

    setLoading(true);
    setError(false);

    const deliveryData = {
      coordinates: {
        origin: delivery.origin.coordinates!,
        destination: delivery.destination.coordinates!,
      },
      floor: delivery.destination.floor,
      floorCount: delivery.destination.floorCount,
      elevator: delivery.destination.elevator,
    };

    try {
      const response = await createCheckout(product, deliveryData);

      const stripe = await getStripe(response.publishableKey);

      const { error } = await stripe.redirectToCheckout({
        sessionId: response.sessionId,
      });

      if (error) {
        console.error(error);
      }
    } catch (error) {
      setError(true);
      console.error(error);
    } finally {
      setLoading(false);
    }
  }, [submitable, product, delivery.origin.coordinates, delivery.destination]);

  return (
    <form
      // @ts-ignore
      onSubmit={handleSubmit(onSubmit)}
      aria-labelledby="orderDetailsLabel"
    >
      <div className="flex flex-col gap-6">
        <h2
          className="text-xl font-semibold md:text-2xl"
          id="orderDetailsLabel"
        >
          Szczegóły zamówienia
        </h2>
        <div className="flex flex-col gap-6">
          <PickDeliveryType />

          <TransportForm />

          <Costs />
        </div>
      </div>

      {submitable && (
        <AgreementSwitch agree={agreeToTerms} setAgree={setAgreeToTerms} />
      )}

      <Button
        // Button of type submit is used to trigger RHF's handleSubmit
        type={submitable ? "button" : "submit"}
        customClasses={`flex items-center justify-center gap-2 h-12 text-xl ml-auto mt-10 duration-200 ease-in ${submitable ? "bg-indigo-600" : "bg-white !text-black"
          } ${loading ? "animate-pulse" : ""}`}
        disabled={!agreeToTerms && submitable}
        onClick={handleCheckout}
      >
        {submitable ? "Płatność" : "Wylicz"}
        <ArrowRightIcon className="h-5 w-5" />
      </Button>

      {error ? (
        <ErrorMessage customClasses="mt-6 text-center">
          Coś poszło nie tak. Spróbuj ponownie lub skontaktuj się z nami!
        </ErrorMessage>
      ) : null}
    </form>
  );
};

export default Form;
