import { RadioGroup } from "@headlessui/react";
import { CheckIcon } from "@heroicons/react/outline";
import type { TransportFormFields } from "common/templates/OrderDetails/TransportFormProvider";
import React from "react";
import { useFormContext } from "react-hook-form";

import ElevatorCheckbox from "./ElevatorCheckbox";
import floorOptions from "./floorOptions";
import PickCount from "./PickCount";

const PickFloor = () => {
  const { setValue, watch } = useFormContext();

  const onRadioChange = (value: TransportFormFields["floor"]) => {
    setValue("floor", value);

    if (value === "multiple") return setValue("floorCount", 1);

    setValue("floorCount", 0);
    setValue("elevator", false);
  };

  const floor = watch("floor");

  const elevator = watch("elevator");

  return (
    <>
      <RadioGroup
        className="flex flex-col gap-2"
        value={floor}
        onChange={onRadioChange}
        aria-label="Wybierz formę odbioru"
      >
        <RadioGroup.Label className="font-semibold">
          Wniesienie instrumentu
        </RadioGroup.Label>

        {floorOptions.map((floorOption) => (
          <RadioGroup.Option key={floorOption.title} value={floorOption.value}>
            {({ checked }) => (
              <div
                className={`relative flex cursor-pointer items-center gap-2 rounded-xl px-6 py-3 text-lg ring-2 ring-inset ring-zinc-900 md:py-4 md:px-8 md:text-xl ${
                  checked ? "text-green-300 ring-green-900" : ""
                }`}
              >
                {checked ? (
                  <CheckIcon className="absolute -top-2 -right-2 h-6 w-6 rounded-full bg-black p-1" />
                ) : (
                  ""
                )}
                <div className="flex w-full justify-between gap-4">
                  <p className="flex flex-col gap-1">
                    {floorOption.title}
                    {floorOption.description ? (
                      <span
                        className={`text-base text-zinc-500 ${
                          elevator && floorOption.value === "multiple"
                            ? "line-through"
                            : ""
                        }`}
                      >
                        {floorOption.description}
                      </span>
                    ) : (
                      ""
                    )}
                  </p>

                  <p>
                    {elevator && floorOption.value === "multiple"
                      ? 400
                      : floorOption.ammount}{" "}
                    PLN
                  </p>
                </div>
              </div>
            )}
          </RadioGroup.Option>
        ))}
      </RadioGroup>

      {floor === "multiple" ? (
        <div className="mt-8 flex flex-col gap-6">
          <PickCount />
          <ElevatorCheckbox />
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export default PickFloor;
