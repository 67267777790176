import type { TransportFormFields } from "common/templates/OrderDetails/TransportFormProvider";
import type { Coordinates } from "common/types";
import {
  CALCULATE_DELIVERY_API_URL,
  CREATE_CHECKOUT_API_URL,
} from "common/utils/api";

export async function calculateDelivery(
  coordinates: {
    origin: Coordinates;
    destination: Coordinates;
  },
  floor: TransportFormFields["floor"],
  floorCount: number,
  elevator: boolean
) {
  try {
    const response = await fetch(CALCULATE_DELIVERY_API_URL, {
      method: "POST",
      body: JSON.stringify({ coordinates, floor, floorCount, elevator }),
    });

    const data = await response.json();

    return data;
  } catch (error) {
    console.error(error);
  }
}

export async function createCheckout(
  product: {
    title: string;
    price: number;
    currency: string;
  },
  delivery: {
    coordinates: {
      origin: Coordinates;
      destination: Coordinates;
    };
    // Implement to REDUX
    address: string;
    floor: TransportFormFields["floor"];
    floorCount: number;
    elevator: boolean;
  }
) {
  try {
    const response = await fetch(CREATE_CHECKOUT_API_URL, {
      method: "POST",
      body: JSON.stringify({ product, delivery }),
    });

    const data = await response.json();

    return data;
  } catch (error) {
    console.error(error);
  }
}
