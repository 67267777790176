import { yupResolver } from "@hookform/resolvers/yup";
import type { OrderState } from "common/redux/order/types";
import React from "react";
import { FormProvider, useForm } from "react-hook-form";

import transportFormSchema from "./transportFormSchema";

interface TransportFormProviderProps {
  children: React.ReactNode;
}

export type TransportFormFields = Omit<
  OrderState["delivery"]["destination"],
  "coordinates"
>;

const defaultValues: TransportFormFields = {
  address: "",
  floor: "first",
  floorCount: 0,
  elevator: false,
};

const TransportFormProvider: React.FC<TransportFormProviderProps> = ({
  children,
}) => {
  const methods = useForm<TransportFormFields>({
    defaultValues: defaultValues,
    resolver: yupResolver(transportFormSchema),
  });

  return <FormProvider {...methods}>{children}</FormProvider>;
};

export default TransportFormProvider;
