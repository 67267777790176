import Input from "common/components/Input";
import React from "react";

import PickFloor from "../PickFloor";
import LocationAutocomplete from "./LocationAutocomplete";

const TransportForm: React.FC = () => {
  return (
    <div className="flex flex-col gap-6 md:gap-8">
      <div className="grid grid-cols-12 gap-4">
        <Input
          name="country"
          id="country"
          type="text"
          label="Kraj"
          customContainerClasses="col-span-12"
          value="🇵🇱 Polska"
          disabled
        />

        <LocationAutocomplete />
      </div>

      <div className="col-span-12">
        <PickFloor />
      </div>
    </div>
  );
};

export default TransportForm;
