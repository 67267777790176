import { TrendingDownIcon } from "@heroicons/react/solid";
import { selectDelivery, selectProduct } from "common/redux/order";
import { useAppSelector } from "common/redux/store";
import React from "react";

import Cost from "./Cost";

const Costs: React.FC = () => {
  const product = useAppSelector(selectProduct());

  const delivery = useAppSelector(selectDelivery());

  if (!product) return <></>;

  const { cost, distance } = delivery;

  const { total, trip, bringing, perKm } = cost;

  const { price, currency } = product;

  return (
    <div className="flex flex-col gap-3 rounded-xl py-3 px-6">
      {trip || bringing ? (
        <Cost title="Wartość produktu" value={price} currency={currency} />
      ) : (
        ""
      )}

      {trip ? (
        <>
          <Cost
            title={`Dostawa (${perKm} PLN x ${distance} km)`}
            value={trip}
            currency="PLN"
          />

          <p className="text-lg font-semibold text-green-900">
            <TrendingDownIcon className="mr-1 inline h-5 w-5" />
            Opcje tańszego transportu dostępne telefonicznie
          </p>
        </>
      ) : (
        ""
      )}

      {bringing ? (
        <Cost title="Wniesienie" value={bringing} currency="PLN" />
      ) : (
        ""
      )}

      <div className="my-1 h-0.5 rounded-full bg-zinc-900" />

      <Cost
        title="Do zapłaty (w tym VAT)"
        value={price + total}
        currency="PLN"
        customClasses="font-semibold"
      />
    </div>
  );
};

export default Costs;
