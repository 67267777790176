import { loadStripe } from "@stripe/stripe-js";

let stripePromise: any;
const getStripe = (publishableKey: any) => {
  if (!stripePromise) {
    stripePromise = loadStripe(publishableKey);
  }
  return stripePromise;
};

export default getStripe;
