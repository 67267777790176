import React from "react";

interface CostProps {
  title: string;
  value: number;
  currency: string;
  customClasses?: string;
}

const Cost: React.FC<CostProps> = ({
  title,
  value,
  currency,
  customClasses,
}) => {
  return (
    <p className={`flex justify-between gap-4 text-lg ${customClasses}`}>
      <span>{title}</span>
      <span>
        {value} {currency}
      </span>
    </p>
  );
};

export default Cost;
