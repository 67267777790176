import type { TransportFormFields } from "common/templates/OrderDetails/TransportFormProvider";

export type FloorOption = {
  title: string;
  value: TransportFormFields["floor"];
  ammount: number;
  description?: string;
};

const floorOptions: Array<FloorOption> = [
  {
    title: "Parter",
    value: "first",
    ammount: 300,
  },
  {
    title: "Wysoki parter",
    description: "Od 5 schodów",
    value: "highFirst",
    ammount: 400,
  },
  {
    title: "Piętro",
    value: "multiple",
    ammount: 500,
    description: "+100 PLN za każde piętro",
  },
];

export default floorOptions;
