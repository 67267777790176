export type DeliveryOption = {
  title: string;
  value: "pickup" | "delivery";
};

const deliveryOptions: Array<DeliveryOption> = [
  {
    title: "Transport",
    value: "delivery",
  },
  {
    title: "Odbiór osobisty",
    value: "pickup",
  },
];

export default deliveryOptions;
