import { LocationMarkerIcon } from "@heroicons/react/solid";
import Button from "common/components/Button";
import { getAddressForCoordinates } from "common/services/Location";
import React, { useState } from "react";

interface UseCurrentLocationButtonProps {
  updateLocation: (address: string) => void;
}

const UseCurrentLocationButton: React.FC<UseCurrentLocationButtonProps> = ({
  updateLocation,
}) => {
  const [geoLocationError, setGeoLocationError] = useState<object | null>(null);

  const fillFormFieldsWithCurrentLocation = (
    coords: GeolocationCoordinates
  ) => {
    getAddressForCoordinates(coords)
      .then((address: string) => updateLocation(address))
      .catch((error) => console.error(error));
  };

  const handleUseCurrentLocation = () => {
    if (!navigator) return;

    navigator.geolocation.getCurrentPosition(
      (e) => {
        fillFormFieldsWithCurrentLocation(e.coords);
      },
      async (error) => {
        setGeoLocationError(error);
      }
    );
  };

  return (
    <>
      <Button
        type="button"
        customClasses="mt-3 inline-flex items-center gap-2 text-red-300 text-base"
        onClick={handleUseCurrentLocation}
        text
      >
        <LocationMarkerIcon className="h-5 w-5" />
        Użyj obecnej lokalizacji
      </Button>

      {geoLocationError ? (
        <p className="mt-1 text-sm text-zinc-700">
          Zezwól na dostęp do lokalizacji aby skorzystać z tej opcji
        </p>
      ) : (
        ""
      )}
    </>
  );
};

export default UseCurrentLocationButton;
