import { Combobox } from "@headlessui/react";
import { GlobeAltIcon, LocationMarkerIcon } from "@heroicons/react/outline";
import Input from "common/components/Input";
import { getPlaces } from "common/services/Location";
// @ts-ignore
import { debounce } from "debounce";
import React, {
  BaseSyntheticEvent,
  Fragment,
  useCallback,
  useEffect,
  useState,
} from "react";
import { type FieldError, Controller, useFormContext } from "react-hook-form";

import UseCurrentLocationButton from "./UseCurrentLocationButton";

const LocationAutocomplete: React.FC = () => {
  const {
    setValue,
    watch,
    formState: { errors },
  } = useFormContext();

  const [searchResults, setSearchResults] = useState<Array<object>>([]);

  const updateLocation = (address: string) => {
    setValue("address", address);
  };

  const searchPlaces = (query: string) => {
    getPlaces(query)
      .then((response: { data: Array<object>; invalidQuery: boolean }) => {
        setSearchResults(response.data);
      })
      .catch((error) => console.error(error));
  };

  const onAutocompleteInputChange = (
    e: BaseSyntheticEvent,
    reactHookFormField: any
  ) => {
    reactHookFormField.onChange(e.target.value);
  };

  const address = watch("address");

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSearchPlaces = useCallback(
    debounce((query: string) => {
      searchPlaces(query);
    }, 300),
    []
  );

  useEffect(() => {
    debouncedSearchPlaces(address);
  }, [address, debouncedSearchPlaces]);

  const onAutocompleteChange = (address: any) => updateLocation(address);

  return (
    <div className="relative col-span-12">
      <Controller
        name="address"
        render={({ field }) => (
          <Combobox onChange={onAutocompleteChange}>
            {({ open }) => {
              return (
                <>
                  {/* @ts-ignore */}
                  <Combobox.Input as={Fragment} autoComplete="off">
                    <Input
                      reactHookFormRegister={field}
                      label="Adres"
                      onChange={(e: BaseSyntheticEvent) =>
                        onAutocompleteInputChange(e, field)
                      }
                      error={errors["address"] as FieldError}
                    />
                  </Combobox.Input>

                  {open && searchResults.length ? (
                    <Combobox.Options
                      static
                      className="absolute z-20 mt-2 flex max-h-72 w-full flex-col gap-2 overflow-y-auto rounded-xl bg-zinc-900 p-4 ring ring-zinc-800"
                    >
                      {searchResults.map((searchResult: any) => (
                        <Combobox.Option
                          key={searchResult.id}
                          value={searchResult.address.freeformAddress}
                          className={({ active }) =>
                            `flex cursor-pointer items-center gap-2 rounded-xl bg-zinc-800 px-4 py-2 ${
                              active ? "brightness-200" : ""
                            }`
                          }
                        >
                          {searchResult.type === "Point Address" ? (
                            <LocationMarkerIcon className="h-5 w-5 shrink-0 text-red-300" />
                          ) : (
                            <GlobeAltIcon className="h-5 w-5 shrink-0" />
                          )}
                          {searchResult.address.freeformAddress}
                        </Combobox.Option>
                      ))}
                    </Combobox.Options>
                  ) : (
                    ""
                  )}
                </>
              );
            }}
          </Combobox>
        )}
      />

      <UseCurrentLocationButton updateLocation={updateLocation} />
    </div>
  );
};

export default LocationAutocomplete;
