import BackButton from "common/components/BackButton";
import Container from "common/components/Container";
import SEO from "common/components/SEO";
import DefaultLayout from "common/layouts/Default";
import Heading from "common/templates/components/Heading";
import type { Product } from "common/types";
import { GatsbyImage } from "gatsby-plugin-image";
import React from "react";

import Form from "./Form";

interface OrderDetailsViewProps {
  product: Product;
}

const OrderDetailsView: React.FC<OrderDetailsViewProps> = ({ product }) => {
  return (
    <DefaultLayout>
      <SEO title={"Kup teraz - " + product.title} />
      <Container>
        <BackButton to={`/sklep/${product.sku}`} />

        <div className="mt-4 grid grid-cols-1 gap-12 md:grid-cols-2 md:gap-14">
          <div className="grid h-full grid-cols-2 gap-6">
            <GatsbyImage
              image={product.images[0].childImageSharp.gatsbyImageData}
              alt={product.title}
              imgClassName="rounded-xl"
              className="h-[200px]"
            />
            <Heading product={product} />
          </div>

          <Form />
        </div>
      </Container>
    </DefaultLayout>
  );
};

export default OrderDetailsView;
