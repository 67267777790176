import { RadioGroup } from "@headlessui/react";
import { CheckIcon } from "@heroicons/react/outline";
import { useOrderContext } from "common/context/OrderContext";
import React from "react";
import { useFormContext } from "react-hook-form";

import deliveryOptions, { type DeliveryOption } from "./deliveryOptions";

const PickDeliveryType = () => {
  const { reset } = useFormContext();

  const { order, updateOrder, resetDelivery } = useOrderContext();

  const onRadioChange = (value: DeliveryOption["value"]) => {
    if (value === "delivery") return updateOrder({ delivery: true });

    reset();
    resetDelivery();
  };

  return (
    <RadioGroup
      className="grid grid-cols-1 gap-4 md:grid-cols-2"
      value={order.delivery ? "delivery" : "pickup"}
      onChange={onRadioChange}
      aria-label="Wybierz formę odbioru"
    >
      {deliveryOptions.map((deliveryOption) => (
        <RadioGroup.Option
          key={deliveryOption.title}
          value={deliveryOption.value}
          disabled={deliveryOption.value === "pickup"}
        >
          {({ checked, disabled }) => (
            <p
              className={`relative flex items-center gap-2 rounded-xl px-6 py-3 text-lg ring-2 ring-inset ring-zinc-900 md:py-4 md:px-8 md:text-xl ${
                checked ? "text-green-300 ring-green-900" : ""
              } ${
                disabled ? "cursor-not-allowed brightness-50" : "cursor-pointer"
              }`}
            >
              {checked ? (
                <CheckIcon className="absolute -top-2 -right-2 h-6 w-6 rounded-full bg-black p-1" />
              ) : (
                ""
              )}
              {deliveryOption.title}
            </p>
          )}
        </RadioGroup.Option>
      ))}
    </RadioGroup>
  );
};

export default PickDeliveryType;
