import { Switch } from "@headlessui/react";
import Button from "common/components/Button";
import React, { type Dispatch, type SetStateAction } from "react";

interface AgreementSwitchProps {
  agree: boolean;
  setAgree: Dispatch<SetStateAction<boolean>>;
}

const AgreementSwitch: React.FC<AgreementSwitchProps> = ({
  agree,
  setAgree,
}) => {
  return (
    <Switch.Group>
      <div className="mt-8 flex items-center">
        <Switch
          checked={agree}
          onChange={setAgree}
          className={`${
            agree ? "bg-green-600" : "bg-transparent"
          } relative inline-flex h-6 w-12 items-center rounded-full ring-1 ring-zinc-900 transition-colors focus-visible:outline-none focus-visible:ring focus-visible:ring-inherit focus-visible:ring-opacity-5`}
        >
          <span
            className={`${
              agree ? "translate-x-6" : "translate-x-1"
            } inline-block h-4 w-4 transform rounded-full bg-zinc-300 transition-transform`}
          />
        </Switch>
        <div className="flex items-center gap-2">
          <Switch.Label className="ml-2 text-lg font-semibold">
            Akceptuję
          </Switch.Label>

          <Button href="/regulamin.pdf" text customClasses="text-blue-600">
            regulamin
          </Button>
        </div>
      </div>
    </Switch.Group>
  );
};

export default AgreementSwitch;
