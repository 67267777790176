import * as yup from "yup";

import type { TransportFormFields } from "./TransportFormProvider";

const requiredFieldErrorText = "To pole jest wymagane";

const transportFormSchema = yup
  .object<TransportFormFields>({
    address: yup.string().required(requiredFieldErrorText),
    floor: yup.string().min(0).required(requiredFieldErrorText),
    floorCount: yup.number().min(0).required(requiredFieldErrorText),
    elevator: yup.boolean().required(requiredFieldErrorText),
  })
  .required();

export default transportFormSchema;
