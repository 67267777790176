import { resetOrder, selectProduct, updateProduct } from "common/redux/order";
import { useAppDispatch, useAppSelector } from "common/redux/store";
import type { Product } from "common/types";
import { graphql } from "gatsby";
import React, { useEffect } from "react";

import OrderDetailsView from "./OrderDetailsView";
import TransportFormProvider from "./TransportFormProvider";

interface OrderDetailsProps {
  data: {
    markdownRemark: {
      frontmatter: Product;
    };
  };
}

const OrderDetails: React.FC<OrderDetailsProps> = ({ data }) => {
  const { markdownRemark } = data;
  const { frontmatter } = markdownRemark;

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(resetOrder());
    dispatch(updateProduct(frontmatter));
  }, [frontmatter, dispatch]);

  const product = useAppSelector(selectProduct());

  if (!product) return <></>;

  return (
    <TransportFormProvider>
      <OrderDetailsView product={product} />
    </TransportFormProvider>
  );
};

export default OrderDetails;

export const data = graphql`
  query ORDER_DETAILS_TEMPLATE_QUERY($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        type
        title
        standard
        condition
        sound
        manufacture {
          built
          countryOfOrigin
          brandOrigin
        }
        look {
          color
          finish
        }
        dimensions {
          height
          length
          width
        }
        location
        price
        currency
        images {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
        date(formatString: "MMMM DD, YYYY")
        sku
      }
    }
  }
`;
