import { ExclamationIcon } from "@heroicons/react/outline";
import React from "react";

interface ErrorMessageProps {
  children: React.ReactNode;
  customClasses?: string;
}

const ErrorMessage: React.FC<ErrorMessageProps> = ({
  children,
  customClasses,
}) => (
  <p className={`text-lg font-semibold text-red-900 ${customClasses}`}>
    <ExclamationIcon className="mr-1 inline h-5 w-5" />
    {children}
  </p>
);

export default ErrorMessage;
