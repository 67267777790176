import React from "react";
import { useFormContext } from "react-hook-form";

const ElevatorCheckbox = () => {
  const { setValue, watch } = useFormContext();

  const elevator = watch("elevator");

  const onCheckboxChange = () => {
    setValue("elevator", !elevator);
  };

  return (
    <div className="flex items-center gap-4 md:gap-6">
      <input
        id="elevatorCheckbox"
        type="checkbox"
        className="h-7 w-7 rounded-md border-none bg-zinc-900 focus:text-blue-600 focus:outline-none focus:ring-0 focus:ring-offset-0"
        checked={elevator}
        onChange={onCheckboxChange}
      />
      <label htmlFor="elevatorCheckbox" className="flex flex-col gap-1">
        <p className="font-semibold">Dostępna winda</p>
        <span className="text-xs text-zinc-500 md:text-sm">
          Winda musi być odpowiednich wymiarów aby pomieścić instrument
        </span>
      </label>
    </div>
  );
};

export default ElevatorCheckbox;
