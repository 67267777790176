import React from "react";
import type { FieldError } from "react-hook-form";

interface InputProps extends React.HTMLProps<HTMLInputElement> {
  label?: string;
  customContainerClasses?: string;
  customClasses?: string;
  reactHookFormRegister?: any;
  disabled?: boolean;
  error?: FieldError;
  innerRef?: React.RefCallback<any>;
}

const Input: React.FC<InputProps> = ({
  id,
  label,
  customContainerClasses,
  customClasses,
  reactHookFormRegister,
  disabled,
  error,
  ...props
}) => {
  return (
    <div
      className={`flex flex-col gap-2 ${customContainerClasses} ${
        disabled ? "brightness-50" : ""
      }`}
    >
      {label ? (
        <label
          className="font-semibold"
          htmlFor={reactHookFormRegister?.name || id}
        >
          {label}
        </label>
      ) : (
        ""
      )}
      <input
        id={reactHookFormRegister?.name || id}
        {...reactHookFormRegister}
        className={`form-input w-full rounded-xl border-none bg-zinc-900 p-4 text-xl placeholder:text-zinc-400 placeholder:text-opacity-40 focus-visible:outline-none focus-visible:ring focus-visible:ring-inherit focus-visible:ring-opacity-5 disabled:cursor-not-allowed ${customClasses} ${
          error ? "text-red-300 ring-1 !ring-red-600" : ""
        }`}
        disabled={disabled}
        {...props}
      />
      {error ? (
        <p role="alert" className="text-red-600">
          {error.message}
        </p>
      ) : (
        ""
      )}
    </div>
  );
};

export default Input;
