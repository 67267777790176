import * as Slider from "@radix-ui/react-slider";
import React from "react";
import { useFormContext } from "react-hook-form";

const PickCount = () => {
  const { watch, setValue } = useFormContext();

  const onSliderChange = (value: number[]) => {
    setValue("floorCount", value[0]);
  };

  const floorCount = watch("floorCount");

  return (
    <div className="flex gap-4">
      <Slider.Root
        value={[floorCount]}
        min={1}
        max={53}
        step={1}
        onValueChange={onSliderChange}
        className="relative flex h-5 w-full touch-none select-none items-center rounded-full hover:cursor-pointer"
        aria-label="Wybierz piętro"
        data-test="pick-floor-count-slider"
      >
        <Slider.Track className="relative h-2 grow rounded-full bg-zinc-900">
          <Slider.Range className="absolute h-full rounded-full bg-blue-600" />
        </Slider.Track>
        <Slider.Thumb className="block h-5 w-5 rounded-full bg-zinc-100" />
      </Slider.Root>

      <p className="shrink-0">{floorCount} piętro</p>
    </div>
  );
};

export default PickCount;
